import { css } from '@emotion/react';
import { ToucanComponents, ToucanColors } from '@jointoucan/toucan-design';
import { useTheme } from '@mui/material';
import { GetStaticProps } from 'next';
import { useTranslation, Trans } from 'next-i18next';

import { Box } from '~/components/shared/Box';
import Layout from '~/components/shared/Layout';
import Link from '~/components/shared/Link';
import { Events } from '~/constants/events';
import { trackEvent } from '~/helpers/analytics';
import Errors from '~/helpers/errors';
import { useMount } from '~/hooks/useMount';
import { getTranslations } from '~/lib/next-i18next';

const { Typography } = ToucanComponents;

const FourOhFour = () => {
  const theme = useTheme();
  const { t } = useTranslation('fourOhFour');

  useMount(() => {
    trackEvent({
      name: Events.FourOhFourPageViewed,
      properties: {
        url: window.location.href,
      },
    });
  });

  return (
    <Layout pageError={Errors.FourOhFourPageError} isContained={false}>
      <Box display="flex" flexDirection="column" alignItems="center" width="100%">
        <Box
          maxWidth="384px"
          css={css`
            margin: ${theme.spacing(15)} ${theme.spacing(2)} 0 ${theme.spacing(2)};
          `}
        >
          <Typography variant="lg" font="secondary" color={ToucanColors.gray[600]}>
            {t('looksLikeWeTookWrongTurn')}
          </Typography>
          <Box mt={2}>
            <Typography variant="sm" color={ToucanColors.gray[500]}>
              <Trans
                i18nKey="sorryAboutThatWeRecommendHeadingHome"
                ns="fourOhFour"
                components={[<Link href="/">aaa</Link>, <Link href="/contact">aaa</Link>]}
              />
            </Typography>
          </Box>
        </Box>
        <img
          src="/images/toucan-map.svg"
          alt={t('toucanWithMap')}
          css={css`
            width: 525px;
            padding-top: ${theme.spacing(20)};
            ${theme.breakpoints.up('sm')} {
              width: 700px;
              padding-top: ${theme.spacing(10)};
            }
          `}
        />
      </Box>
    </Layout>
  );
};

export default FourOhFour;

export const getStaticProps: GetStaticProps = async ({ locale }) => ({
  props: {
    ...(await getTranslations({
      locale,
      namespaces: 'fourOhFour',
    })),
  },
});
